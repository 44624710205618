/**
 * Nosy Work
 *
 * @export Work
 */
import {
    AspectRatio,
    Box,
    Center,
    chakra,
    Container,
    Flex,
    Heading,
    LinkBox,
    SimpleGrid,
    Skeleton,
    Stack,
    Stat,
    StatHelpText,
    StatNumber,
    Text,
    Tooltip,
    useBreakpointValue
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import { graphql } from 'gatsby'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import BackLink from '../../components/back-link/back-link'
import FeaturedLink from '../../components/featured-link/featured-link'
import Image from '../../components/image/image'
import Layout from '../../components/layout/layout'
import Link from '../../components/link/link'
import QuoteMark from '../../components/quote-mark/quote-mark'
import { WorkRow } from '../../components/sections'
import SEO from '../../components/seo/seo'
import { defaultSerializer, heroSerializer, leadSerializer } from '../../serializers'
import { analyseColor } from '../../utils/helpers'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_WORK_QUERY($id: String!) {
        sanityWork(id: { eq: $id }) {
            title
            heading
            seo: _rawSeo(resolveReferences: { maxDepth: 10 })
            excerpt: _rawExcerpt(resolveReferences: { maxDepth: 10 })
            stats: _rawStats(resolveReferences: { maxDepth: 10 })
            body: _rawBody(resolveReferences: { maxDepth: 10 })
            workUrl
            sector
            featureVideo {
                url
            }
            featureImage {
                ...ImageWithPreview
            }
            focusVideo {
                url
            }
            focusImage {
                ...ImageWithPreview
            }
            colorPalette: _rawColorPalette(resolveReferences: { maxDepth: 10 })
            gallery {
                images {
                    _key
                    ...ImageWithPreview
                }
            }
            closingVideo {
                url
            }
            closingImage {
                ...ImageWithPreview
            }
            disciplines: _rawDiscipline(resolveReferences: { maxDepth: 10 })
            team: _rawTeam(resolveReferences: { maxDepth: 10 })
            testimonial: _rawTestimonial(resolveReferences: { maxDepth: 10 })
            largeGallery {
                images {
                    _key
                    ...ImageWithPreview
                }
            }
            relatedWork: _rawRelatedWork(resolveReferences: { maxDepth: 10 })
            slug {
                current
            }
        }
    }
`

const Work = ( props ) => {
    // console.log( '🦄 ~ file: work.js ~ line 30 ~ Work ~ props', { props } )

    const {
        data: {
            sanityWork: {
                title = '',
                heading = '',
                seo: { seoTitle = '', seoDescription = '' },
                featureVideo = {},
                featureImage = {},
                focusVideo = {},
                focusImage = {},
                gallery = {},
                closingVideo = {},
                closingImage = {},
                excerpt = [],
                body = [],
                workUrl = '',
                sector = '',
                stats = [],
                testimonial = {},
                team = [],
                disciplines = [],
                colorPalette = [],
                largeGallery = [],
                relatedWork = [],
                slug: { current: url = '' }
            }
        }
    } = props

    const ChakraReactPlayer = chakra( ReactPlayer, {
        shouldForwardProp: ( prop ) => prop
    } )

    // Setup video loading states
    const [loadingFeatureVideo, setLoadingFeatureVideo] = useState( true )
    const [loadingFocusVideo, setLoadingFocusVideo] = useState( true )
    const [loadingClosingVideo, setLoadingClosingVideo] = useState( true )

    const fullImageWidths = useBreakpointValue( {
        base: 447,
        sm: 735,
        md: 959,
        lg: 1120
    } )

    const halfImageWidths = useBreakpointValue( {
        base: 447,
        sm: 328,
        md: 440,
        lg: 520
    } )

    return (
        <Layout>
            <SEO
                title={`${seoTitle} | Work`}
                description={seoDescription}
                image={featureImage}
                url={`/work/${url}/`} />
            <Container maxWidth='6xl' mb={40}>
                <Tooltip
                    label='See all work'
                    aria-label='See all work'
                    fontSize='md'
                    placement='top'
                    backgroundColor='brand_primary.700'
                    color='white'
                    arrowShadowColor='brand_primary.700'>
                    <Box as='span' width='32px' height='32px' display='block'>
                        <BackLink to='/work/' />
                    </Box>
                </Tooltip>
                <Center flexDirection='column'>
                    <Heading
                        as='h1'
                        fontSize='md'
                        fontWeight='normal'
                        color='gray.500'
                        borderColor='gray.500'
                        borderWidth='1px'
                        borderRadius='full'
                        px={6}
                        py={3}>
                        {title}
                    </Heading>
                    <Heading
                        fontSize={['5xl', '4xl', '6xl', '7xl']}
                        textAlign='center'
                        color='gray.100'
                        mt={12}
                        mb={[20, 40]}>
                        {heading}
                    </Heading>
                </Center>

                {featureVideo ? (
                    <AspectRatio ratio={16 / 9} mb={[20, 40]}>
                        <Skeleton
                            isLoaded={!loadingFeatureVideo}
                            startColor='brand_primary.700'
                            endColor='brand_grayscale.darker'
                            width='full'
                            speed={1}>
                            <ChakraReactPlayer
                                onReady={() => setLoadingFeatureVideo( false )}
                                url={featureVideo.url}
                                width='full'
                                height='full'
                                playing={process.env.NODE_ENV === 'production'}
                                muted
                                controls
                                config={{
                                    youtube: {
                                        playerVars: {
                                            autoplay:
                                                process.env.NODE_ENV ===
                                                'production'
                                                    ? 1
                                                    : 0,
                                            modestbranding: 1,
                                            playsinline: 1
                                        }
                                    },
                                    vimeo: {
                                        playerOptions: {
                                            autoplay:
                                                process.env.NODE_ENV ===
                                                'production'
                                                    ? 1
                                                    : 0,
                                            byline: false,
                                            playsinline: true
                                        }
                                    }
                                }} />
                        </Skeleton>
                    </AspectRatio>
                ) : (
                    <Image
                        imageData={featureImage}
                        loading='eager'
                        objectFit='contain'
                        width={fullImageWidths}
                        mb={[20, 40]}
                        sx={{
                            width: 'full'
                        }} />
                )}

                <SimpleGrid
                    columns={stats ? [1, null, 2] : 1}
                    spacing={10}
                    mb={stats === null ? [20, 40] : [16, 32]}>
                    {stats && (
                        <Stack direction='column' spacing={10}>
                            {stats.map( ( stat ) => {
                                const {
                                    _key: key = '',
                                    statValue = '',
                                    statLabel = ''
                                } = stat
                                return (
                                    <Stat key={key} color='gray.100'>
                                        <StatNumber
                                            fontSize='6xl'
                                            lineHeight='shorter'
                                            fontFamily='Modelica-SS01-Bold'>
                                            {statValue}
                                        </StatNumber>
                                        <StatHelpText
                                            fontSize='lg'
                                            maxWidth={['full', '42%']}>
                                            {statLabel}
                                        </StatHelpText>
                                    </Stat>
                                )
                            } )}
                        </Stack>
                    )}
                    <Box maxWidth={'full'}>
                        <PortableText 
                            value={excerpt} 
                            components={leadSerializer} />
                    </Box>
                </SimpleGrid>

                {focusVideo ? (
                    <AspectRatio 
                        ratio={16 / 9} 
                        mb={[20, 40]}>
                        <Skeleton
                            isLoaded={!loadingFocusVideo}
                            startColor='brand_primary.700'
                            endColor='brand_grayscale.darker'
                            width='full'
                            speed={1}>
                            <ChakraReactPlayer
                                onReady={() => setLoadingFocusVideo( false )}
                                url={focusVideo.url}
                                width='full'
                                height='full'
                                playing={process.env.NODE_ENV === 'production'}
                                muted
                                controls
                                config={{
                                    youtube: {
                                        playerVars: {
                                            autoplay: process.env.NODE_ENV ==='production'
                                                ? 1
                                                : 0,
                                            modestbranding: 1,
                                            playsinline: 1
                                        }
                                    },
                                    vimeo: {
                                        playerOptions: {
                                            autoplay: process.env.NODE_ENV === 'production'
                                                ? 1
                                                : 0,
                                            byline: false,
                                            playsinline: true
                                        }
                                    }
                                }} />
                        </Skeleton>
                    </AspectRatio>
                ) : (
                    <Image
                        imageData={focusImage}
                        width={fullImageWidths}
                        mb={[20, 40]}
                        sx={{
                            width: 'full'
                        }} />
                )}

                {colorPalette && (
                    <Stack direction='column' spacing={3} mb={[20, 40]}>
                        {colorPalette.map( ( color, index ) => (
                            <Flex
                                px={5}
                                key={color._key}
                                bg={color.colorValue.hex}
                                color={
                                    analyseColor( color.colorValue.hex ) ===
                                    'light'
                                        ? 'brand_grayscale.darker'
                                        : 'brand_grayscale.lighter'
                                }
                                justifyContent='space-between'
                                alignItems='center'
                                height={20}>
                                <Text fontSize='lg' fontWeight={500}>
                                    {color.colorName}
                                </Text>
                                <Text fontSize='lg' fontWeight={500}>
                                    {color.colorValue.hex.toUpperCase()}
                                </Text>
                            </Flex>
                        ) )}
                    </Stack>
                )}

                <SimpleGrid columns={[1, 2]} spacing={20} mb={[4, null, 40]}>
                    <Box>
                        <PortableText
                            value={body}
                            components={defaultSerializer} />
                    </Box>
                    {gallery !== null && (
                        <Box
                            order={1}
                            alignSelf='flex-start'
                            position='sticky'
                            top={0}>
                            <Stack direction='column' spacing={[3, null, 6]}>
                                {gallery.images.map( ( image ) => (
                                    <Image
                                        key={image._key}
                                        imageData={image}
                                        width={halfImageWidths}
                                        objectFit='contain'
                                        sx={{
                                            width: 'full'
                                        }} />
                                ) )}
                            </Stack>
                        </Box>
                    )}
                </SimpleGrid>

                {closingVideo ? (
                    <AspectRatio ratio={16 / 9} mb={[20, 40]}>
                        <Skeleton
                            isLoaded={!loadingClosingVideo}
                            startColor='brand_primary.700'
                            endColor='brand_grayscale.darker'
                            width='full'
                            speed={1}>
                            <ChakraReactPlayer
                                onReady={() => setLoadingClosingVideo( false )}
                                url={closingVideo.url}
                                width='full'
                                height='full'
                                playing={process.env.NODE_ENV === 'production'}
                                muted
                                controls
                                config={{
                                    youtube: {
                                        playerVars: {
                                            autoplay:
                                                process.env.NODE_ENV ===
                                                'production'
                                                    ? 1
                                                    : 0,
                                            modestbranding: 1,
                                            playsinline: 1
                                        }
                                    },
                                    vimeo: {
                                        playerOptions: {
                                            autoplay:
                                                process.env.NODE_ENV ===
                                                'production'
                                                    ? 1
                                                    : 0,
                                            byline: false,
                                            playsinline: true
                                        }
                                    }
                                }} />
                        </Skeleton>
                    </AspectRatio>
                ) : (
                    <Image
                        imageData={closingImage}
                        width={fullImageWidths}
                        mb={[20, 40]}
                        sx={{
                            width: 'full'
                        }} />
                )}

                <SimpleGrid
                    columns={testimonial === null ? [1] : [1, 2]}
                    spacing={10}>
                    <Stack
                        direction='column'
                        spacing={10}
                        borderTop='1px'
                        borderColor='gray.500'
                        pt={6}>
                        <LinkBox>
                            <FeaturedLink
                                to={workUrl}
                                label='Link to website'
                                target='_blank' />
                        </LinkBox>
                        <SimpleGrid
                            columns={testimonial === null ? [1, 2, 4] : [1, 2]}
                            spacing={10}>
                            <Box>
                                <Text fontSize='lg' color='gray.500' mb={4}>
                                    Client
                                </Text>
                                <Text fontSize='lg' color='gray.100'>
                                    {title}
                                </Text>
                            </Box>
                            <Box>
                                <Text fontSize='lg' color='gray.500' mb={4}>
                                    Disciplines
                                </Text>
                                <Stack direction='column' spacing={2}>
                                    {disciplines &&
                                        disciplines.map( ( discipline ) => {
                                            // Check if parent or child discipline and build url
                                            let link
                                            // If top-level discipline, use slug.current for url, Else fallback to parent slug.cirrent for url
                                            if (
                                                discipline.parentDiscipline ===
                                                undefined
                                            ) {
                                                link = `/disciplines/${discipline.slug.current}/`
                                            } else {
                                                link = `/disciplines/${discipline.parentDiscipline.slug.current}/`
                                            }
                                            return (
                                                <Link
                                                    key={discipline._id}
                                                    to={link}
                                                    fontSize='lg'
                                                    color='gray.100'
                                                    width='max'>
                                                    {discipline.title}
                                                </Link>
                                            )
                                        } )}
                                </Stack>
                            </Box>
                            <Box>
                                <Text fontSize='lg' color='gray.500' mb={4}>
                                    Sector
                                </Text>
                                <Text fontSize='lg' color='gray.100'>
                                    {sector}
                                </Text>
                            </Box>
                            <Box>
                                <Text fontSize='lg' color='gray.500' mb={4}>
                                    Team
                                </Text>
                                <Stack direction='column' spacing={2}>
                                    {team &&
                                        team.map( ( t ) => {
                                            // Link to Team member is isCurrent === true (i.e still with NOSY)
                                            if ( t.isCurrent === true ) {
                                                return (
                                                    <Link
                                                        key={t._id}
                                                        to={`/team/${t.slug.current}/`}
                                                        fontSize='lg'
                                                        color='gray.100'
                                                        width='max'>
                                                        {t.name}
                                                    </Link>
                                                )
                                            }

                                            return (
                                                <Text
                                                    key={t._id}
                                                    fontSize='lg'
                                                    color='gray.100'
                                                    width='max'>
                                                    {t.name}
                                                </Text>
                                            )} 
                                        )}
                                </Stack>
                            </Box>
                        </SimpleGrid>
                    </Stack>
                    {testimonial && (
                        <Stack
                            direction='column'
                            spacing={10}
                            pt={6}
                            mt={[10, null, -20]}>
                            <QuoteMark
                                width={16}
                                height={16}
                                color='brand_secondary.500' />
                            <PortableText
                                value={testimonial.quote}
                                components={heroSerializer} />
                            <Text mt={0} fontSize='md' color='gray.100'>
                                - {testimonial.name}, {testimonial.role}
                            </Text>
                        </Stack>
                    )}
                </SimpleGrid>
            </Container>

            <Container maxWidth='6xl'>
                {largeGallery !== null && (
                    <Box order={1}>
                        <Stack
                            direction='column'
                            spacing={[3, null, 6]}
                            my={[20, 40]}>
                            {largeGallery.images.map( ( image ) => (
                                <Image
                                    key={image._key}
                                    imageData={image}
                                    objectFit='contain'
                                    width={fullImageWidths}
                                    sx={{
                                        width: 'full'
                                    }} />
                            ) )}
                        </Stack>
                    </Box>
                )}
            </Container>

            <Container maxWidth='6xl'>
                <WorkRow heading='Related work' work={relatedWork} />
            </Container>
        </Layout>
    )
}

export default Work
