/**
 * Nosy FeaturedLink
 *
 * @export FeaturedLink
 */
import { Heading, LinkOverlay, Stack } from '@chakra-ui/react'
import React from 'react'
import Link from '../../components/link/link'
import ForwardArrow from '../foward-arrow/forward-arrow'

const FeaturedLink = ( { to = '#', label = '', ...rest } ) => (
    <LinkOverlay
        as={Link}
        to={to}
        role='group'
        transition='all 0.343s'
        _hover={{ color: 'brand_secondary.500' }}
        {...rest}>
        <Stack direction='row' spacing={6}>
            <Heading
                as='h5'
                color='gray.100'
                fontSize='xl'
                transition='all 0.343s'
                _groupHover={{
                    color: 'brand_secondary.500'
                }}>
                {label}
            </Heading>
            <ForwardArrow 
                color='brand_secondary.500'
                transition='all 0.343s'
                width='21px' 
                height='24px'
                transform='rotate(-45deg)' />
        </Stack>
    </LinkOverlay>
)

export default FeaturedLink